import React from "react";

function ReportsTable({ data, id }) {
    console.log(data?.resultList);
    return (
        <div className="w-100 ">
            <div className="w-100 none-flex-item m-t-40">
                <table className="bordered-table-2">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>#</th>
                            <th>Age</th>
                            <th>Diagnosis</th>
                            <th className="w-25">Name</th>
                            <th>Lab Test</th>
                            {/* <th className="w-25">Patient Id</th> */}

                        </tr>
                    </thead>

                    <tbody className="white-bg view-det-pane">
                        {data?.resultList?.map(row => (
                            <tr key={row.id}>
                                <td>{row?.id}</td>
                                <td>{row?.age}</td>
                                <td>{row?.diagnosis}</td>
                                <td>{row?.patientFullName}</td>
                                <td>{row?.testRequests[0].labTest}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReportsTable;
